import { createAction, props } from '@ngrx/store';
import { Feedback } from '../../models';
import { KbCategory } from '../../kb/models/kbcategory.model';
import { KbArticle } from 'src/app/kb/models/kbarticle.model';

export const SaveFeedbackRequest = createAction(
    '[Layout] Save Feedback Request', 
    props<{feedback: Feedback}>()
);

export const SaveFeedbackSuccess = createAction(
    '[Layout] Save Feedback Success'
);

export const SaveFeedbackFailed = createAction(
    '[Layout] Save Feedback Failed'
);

export const setShortcut = createAction(
    '[Layout] Set Shortcut', 
    props<{shortcut: string}>()
);

export const GotoArticles = createAction(
    '[Layout] Go to Articles'
);

export const GotoHelpCenter = createAction(
    '[Layout] Go to Help Center'
);

export const setInfoTab = createAction(
    '[Layout] Set Info Tab', 
    props<{tab: string}>()
);


export const scrollToSession = createAction(
    '[Layout] Scroll to Session', 
    props<{id: string}>()
);

export const setTitle = createAction(
    '[Layout] Set Title', 
    props<{title: string, desc: string}>()
);

export const setSelectedProperty = createAction(
    '[Layout] Set Property', 
    props<{id: number}>()
);

export const setSelectedViewMode = createAction(
    '[Layout] Set View Mode', 
    props<{mode: string}>()
);

export const persistVisitorSelection = createAction(
    '[Layout] Persist Visitor Selection', 
    props<{visitor_id: string, view: string}>()
);

export const FocusMessageBox = createAction(
    '[Layout] Focus Message Box',
);

export const setSelectedCategory = createAction(
    '[Layout] Set selected category', 
    props<{category_id: number}>()
);

export const setSelectedArticle = createAction(
    '[Layout] Set selected article', 
    props<{article_id: number}>()
);

export const SearchArticle = createAction(
    '[Layout] Search Article by term', 
    props<{search: string}>()
);

export const ArticlesRequestSuccess = createAction(
    '[Layout] Get Articles by term and category', 
    props<{articles: KbArticle[]}>()
);

export const SingleArticleRequestSuccess = createAction(
    '[Layout] Get Single Article', 
    props<{article: KbArticle}>()
);

export const insertArticle = createAction(
    '[Layout] insert Article in Message', 
    props<{article_id: number}>()
);

export const NewTicketsIndicator = createAction(
    '[Layout] New Tickets Indicator'
);

export const ResetNewTicketsIndicator = createAction(
    '[Layout] Reset New Tickets Indicator'
);

export const HubDisconnected = createAction(
    '[Layout] The Hub was disconnected',
    props<{is_disconnected: boolean}>()
);
