import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { currentAuthToken } from './auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '../core.state';
import * as models from '../../models';

const API_URL = environment.apiUrl;
const API_USERS_URL = `${API_URL}/api/users`;
const API_SESSION_URL = `${API_URL}/api/session`;

@Injectable()
export class AuthService {


    constructor(
        private http: HttpClient,
        private store: Store<AppState> ) {
            console.debug("AuthService constructed");
        }

    getUserByToken(): Observable<models.User> {

        return this.http.get<models.User>(API_USERS_URL);

        /*return Observable.create((observer: Observer<models.User>) => {
            this.store.pipe(select(currentAuthToken))
              .subscribe(res => {

                const httpHeaders = new HttpHeaders();
                httpHeaders.append('Authorization', 'Bearer ' + res);
                this.http.get<models.User>(API_USERS_URL, { headers: httpHeaders }).subscribe(
                    user => {
                        observer.next(user);
                        observer.complete();
                    }
                );

              }, err => observer.error(err))
        })*/

    }

    login(email: string, password: string, return_url: string): Observable<models.User> {
        return this.http.post<models.User>(`${API_SESSION_URL}/login`, { email, password, return_url });
    }

    register(user: models.User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<models.User>(API_USERS_URL, user, { headers: httpHeaders })
            .pipe(
                map((res: models.User) => {
                    return res;
                })
            );
    }

    check_login_context(return_url: string): Observable<models.Response> {
        return this.http.post<models.Response>(`${API_SESSION_URL}/check-login-context`, {return_url});
    }

    logout(): Observable<models.User> {
        return this.http.get<models.User>(`/logout`);
    }

    updateAvailability(org_id: number, availability: number): Observable<models.UserResponse> {
        return this.http.put<models.UserResponse>(`${API_USERS_URL}/update_availability?org_id=${org_id}`, {
            available: availability
        });
    }

    update_profile(profile: models.ProfileUpload): Observable<models.UserResponse> {

        let form_data = new FormData();
        form_data.append('fullname', profile.fullname);
        form_data.append('email', profile.email);
        form_data.append('password', profile.password);
        form_data.append('photo', profile.photo);
        form_data.append('organization_id', profile.organization_id.toString());
        form_data.append('notify_sound', profile.notify_sound ? 'true' : 'false');
        form_data.append('notify_flash', profile.notify_flash ? 'true' : 'false');
        form_data.append('notify_popup', profile.notify_popup ? 'true' : 'false');
        
        return this.http.post<models.UserResponse>(
                `${API_USERS_URL}/update_profile`, 
                form_data,
                /*{ headers: new HttpHeaders({ "Content-Type": "multipart/form-data" }) }*/

            );
    }

    
    public forgotPassword(email: string): Observable<any> {
    	return this.http.post(API_USERS_URL + '/forgotpassword', {email: email});
    }

    public resetPassword(email: string, password: string, reset_token:string): Observable<any> {
    	return this.http.post(API_USERS_URL + '/resetpassword', {
            email: email,
            password: password,
            reset_token: reset_token
        });
    }

}
