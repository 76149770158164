import { AppState } from '../core.state';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LayoutState } from './layout.reducer';
import { selectAllArticles, selectAllCategories } from '../../kb/store/kb.selectors';

export const selectLayoutFeature = createFeatureSelector<LayoutState>('layout');
 
export const selectcurrentShortcut = createSelector(
    selectLayoutFeature,
    state => state.selectedShortcut
);

export const selectInfoTab = createSelector(
    selectLayoutFeature,
    state => state.infoTab
);

export const selectPendingArticle = createSelector(
    selectLayoutFeature,
    selectAllArticles,
    (layout , articles) => (layout.pendingArticle) ? articles.find(q => q.id == layout.pendingArticle) : null
);


export const selectScrollToSession = createSelector(
    selectLayoutFeature,
    state => state.scrollToSession
);

export const selectTitle = createSelector(
    selectLayoutFeature,
    state => state.title
);

export const selectDesc = createSelector(
    selectLayoutFeature,
    state => state.desc
);

export const selectSelectedProperty = createSelector(
    selectLayoutFeature,
    state =>  state.selectedProperty
);

export const selectViewMode = createSelector(
    selectLayoutFeature,
    state =>  state.selectedViewMode
);

export const selectOpenVisitor = createSelector(
    selectLayoutFeature,
    state =>  state.selectedVisitorOpen
);

export const selectMineVisitor = createSelector(
    selectLayoutFeature,
    state =>  state.selectedVisitorMine
);


export const selectClosedVisitor = createSelector(
    selectLayoutFeature,
    state =>  state.selectedVisitorClosed
);

export const selectKbSearch = createSelector(
    selectLayoutFeature,
    state =>  state.searchKb
);

export const selectselectedArticle = createSelector(
    selectLayoutFeature,
    (layout) =>  layout.articlesFiltered.find(q => q.id == layout.selectedKbArticle)
);

export const selectselectedCategory = createSelector(
    selectLayoutFeature,
    selectAllCategories,
    (layout , categories) =>  (layout.selectedKbCategory) ? categories.find(q => q.id == layout.selectedKbCategory) : null
);

export const selectArticlesFiltered = createSelector(
    selectLayoutFeature,
    state =>  state.articlesFiltered
);

export const selectNewTicketsIndicator = createSelector(
    selectLayoutFeature,
    state =>  state.newTickets
);

export const selectisHubDisconnected = createSelector(
    selectLayoutFeature,
    state =>  state.isHubDisconnected
);